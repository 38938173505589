html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Animation */

.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.App {
  display: flex;
  margin: auto;
}

.section-header {
  display: flex;
  padding-bottom: 42px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  max-width: 1000px; /* Match the width of the about section */
  margin: 0 auto; /* Center the section */
  box-sizing: border-box;
}

.section-header::after {
  content: "";
  display: block;
  width: 300px;
  height: 1px;
  margin-top: 39px;
  margin-left: 20px;
  background-color: #36c2ce; /* Match the section title color */
}

.section-title {
  color: #36c2ce;
  font-size: 46px;
  font-weight: bold;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .App {
    flex-direction: column;
    padding: 40px 24px;
  }

  #content {
    padding: 10px 10px;
    width: 100%;
  }

  .section-header {
    width: unset;
  }

  .section-header::after {
    display: none;
  }

  .section-title {
    font-size: 32px;
  }
}
