/* HEADER STYLES */

.header {
  position: fixed;
  height: 60px; /* Reduced height */
  z-index: 1000; /* High z-index value */
  width: 100%;
  top: 0;
  left: 0;
  transition: 0.3s ease-in;
  background-color: #24292e;
  font-family: var(--font-family);
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  margin: auto;
  height: 100%;
  padding: 0 2rem; /* Reduced padding on sides */
}

.header .nav-menu {
  display: flex;
  list-style: none; /* Remove bullets */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.header .nav-menu a {
  color: #fafbfc; /* Text color */
  text-decoration: none; /* Remove underline */
}

.header .nav-menu .nav-item {
  padding: 0.5rem; /* Reduced padding */
  font-weight: 500;
}

.header .nav-menu .nav-item a:hover {
  padding-bottom: 8px; /* Adjusted padding */
  border-bottom: 3px solid #36c2ce;
}

.hamburger {
  display: none;
  cursor: pointer; /* Show pointer cursor on hover */
}

@media screen and (max-width: 788px) {
  .header {
    background-color: #24292e; /* No background color for mobile */
  }

  .header .navbar {
    padding: 0 1rem; /* Maintain padding on sides for mobile */
  }

  .hamburger {
    display: block;
    color: #fafbfc; /* Icon color */
    font-size: 2rem; /* Adjust as needed */
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 60px; /* Adjusted top position */
    flex-direction: column;
    background-color: #24292e; /* Background color */
    width: 100%;
    height: 100vh;
    z-index: 1000; /* Ensure it covers the entire page */
    text-align: center;
    transition: 0.3s;
    padding-top: 1rem;
    overflow: hidden; /* Hide content outside */
  }

  .nav-menu.active {
    left: 0; /* Slide in from the left */
  }

  .nav-item {
    margin: 1.5rem 0;
  }

  .header .navbar img {
    width: 120px; /* Adjusted width for mobile */
  }
}

.code-by-debo {
  color: #fafbfc; /* Change text color to black */
  text-decoration: none; /* Remove underline */
  padding-bottom: 0; /* Remove bottom padding */
  margin-bottom: 0; /* Remove bottom margin if needed */
}

.logo {
  text-decoration: none; /* Ensure no underline on link */
}
