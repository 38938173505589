body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@500&family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@500&family=Open+Sans&family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

:root {
  --font-family: "Manrope", sans-serif;
  --font-family2: "Open Sans", sans-serif;
  --font-family3: "Arimo", sans-serif;
  --font-family4: "Roboto", sans-serif;
  --font-family5: "Montserrat, sans-serif";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
