/* HOMEPAGE SECTION */

body {
  background-color: #2b3137;
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
}

body::-webkit-scrollbar {
  display: none; /* WebKit (Chrome, Safari, etc.) */
}

.section {
  padding-top: 4vh;
  height: 96vh;
  box-sizing: border-box;
  min-height: fit-content;
}

.hero.section {
  padding-top: 5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.hero_text {
  position: relative; /* Ensure it's positioned correctly */
  z-index: 1; /* Lower z-index value compared to header */
  text-align: center;
  color: #fafbfc; /* Ensure text is visible against the fractal tree */
  padding: 0 2rem;
}

.hero_pic_container {
  display: flex; /* Use flexbox for the container */
  align-items: center; /* Center the image horizontally */
  justify-content: center; /* Center the image horizontally */
  margin: 0; /* Remove any margins */
  padding: 0; /* Remove any padding */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.hero_image {
  width: 80%;
}

.hero_name {
  color: #36c2ce;
}

.hero_h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.hero_subheader {
  font-size: 1.75rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.hero_subheader_ideas {
  color: #fafbfca1;
  font-weight: bold;
}

.hero_subheader_reality {
  color: #36c2ce;
  font-weight: bold;
}

.custom-typing-effect .typing-effect__cursor {
  color: #36c2ce !important; /* Apply your desired cursor color */
}

/* Ensure no additional cursors are added */
.custom-typing-effect .typing-effect__cursor::after {
  content: ""; /* Remove any additional cursor symbol */
}

.btn-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.btn-1,
.btn-2 {
  font-weight: 600;
  transition: all 300ms ease;
  padding: 1rem;
  width: 8rem;
  border-radius: 2rem;
}

.btn-1,
.btn-2 {
  border: rgb(53, 53, 53) 0.1rem solid;
}

.btn-1 {
  background: #fff;
}

.btn-1:hover,
.btn-2:hover {
  cursor: pointer;
}

.btn-1:hover {
  background: #36c2ce;
  color: white;
}

.btn-2 {
  background: #000;
  color: white;
}

.btn-2:hover {
  background: #36c2ce;
  color: #000;
}

.socials_container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.cursor {
  color: #36c2ce;
}

.icon {
  cursor: pointer;
  height: 2rem;
}

.file-icon {
  margin-left: 0.25 rem;
  padding-bottom: 0.2rem;
  font-size: 1.2rem;
  display: inline-flex;
  vertical-align: middle;
}

.email-icon {
  margin-left: 0.25rem;
  padding-bottom: 0.2rem;
  font-size: 1.2rem;
  display: inline-flex;
  vertical-align: middle;
}

.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, color 0.3s ease;
  color: #fff; /* Default icon color */
  font-size: 35px; /* Adjust font size as needed */
}

.social-icon:hover {
  transform: scale(1.2); /* Scale up on hover */
}

#about {
  min-height: 50vh;
  margin: auto;
  max-width: 1000px;
  padding-top: 100px;
  box-sizing: border-box;
}

.about-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.about-description {
  max-width: 600px;
  font-size: 22px;
  margin: 0;
  color: #ffffff;
}

.about-image {
  padding-left: 40px;
}

.about-image:hover {
  transform: scale(1.05); /* Scale up on hover */
}

.about-description li {
  position: relative;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

.about-description li::before {
  content: "▹    ";
  color: #36c2ce;
  font-size: 24px;
  font-weight: bold;
  margin-right: 10px;
}

.about-image img {
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  margin-left: 30px;
  box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
  border: none;
}

.tech-stack {
  padding-top: 20px;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 10px; /* Gap between items */
}

.tech-stack li {
  font-size: 18px;
  position: relative;
  padding-left: 20px; /* Space for the symbol */
}

.tech-stack li::before {
  content: "▹    ";
  color: #36c2ce;
  position: absolute;
  left: 0;
  font-size: 22px; /* Adjust size of the symbol */
  line-height: 1.5;
  font-weight: bold;
}

.no-underline {
  text-decoration: none;
}

.highlight-1 {
  color: #36c2ce;
}

.highlight-2 {
  color: #fafbfc;
}

/* Projects Section */
#projects {
  min-height: 50vh;
  margin: auto;
  max-width: 1000px;
  padding-top: 100px;
  box-sizing: border-box;
}

/* Spotlight carousel */
.carousel.slide {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 60px;
}

.carousel-inner {
  position: relative; /* Ensures child elements can be positioned absolutely */
  height: 500px; /* Ensure consistent height */
  border-radius: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  overflow: hidden; /* Prevents content from spilling out */
}

.carousel-item {
  position: relative; /* Ensures the caption can be absolutely positioned inside */
}

.carousel img {
  object-fit: cover; /* Adjust image to cover the carousel item */
  height: 100%; /* Ensure images fill the height */
  width: 100%; /* Ensure images fill the width */
  opacity: 0.5; /* Optional opacity */
}

/* Centering caption inside the carousel-item */
.carousel-caption {
  position: absolute; /* Absolute positioning to center within the carousel-item */
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); /* Centers the caption horizontally and vertically */
  text-align: center; /* Center text alignment */
  color: white; /* Ensure text is visible against background */
  padding: 1rem; /* Optional padding */
  max-width: 80%; /* Restricts width for better readability */
  width: 100%; /* Ensure full width */
}

.carousel-item-custom {
  position: relative; /* Set positioning context for the carousel-inner */
}

.carousel-inner {
  position: relative; /* Set positioning context for carousel-content */
}

.carousel-content {
  position: absolute; /* Position absolutely within carousel-inner */
  bottom: 0; /* Align to the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  text-align: center; /* Center text inside the content */
  width: 100%; /* Full width for carousel-content */
  padding: 20px; /* Add padding */
  color: white; /* Text color */
  border-radius: 12px 12px 0 0; /* Rounded top corners */
  padding-bottom: 5rem;
}

.carousel-content h3 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #31363f; /* Text color */
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.8); /* Outline effect */
}

/* Style for images inside the carousel */
.carousel-item img {
  object-fit: cover; /* Ensure images cover the container without distortion */
  width: 100%; /* Full width of the container */
  height: 100%; /* Full height of the container */
  opacity: 0.5; /* Ensure images are fully visible */
}

.carousel-content p {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #31363f; /* Text color */
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.8); /* Outline effect */
}

.carousel-content .techStack {
  display: block;
  font-size: 14px;
  color: #76abae;
  font-weight: bold;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.8); /* Outline effect */
}

.carousel-content .external-links {
  margin-top: 10px;
}

.carousel-caption p.techStack {
  font-family: "NTR", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #76abae;
}

.carousel-indicators .active {
  background-color: var(--green-bright);
}

@media only screen and (max-width: 600px) {
  #projects {
    height: unset;
    padding-left: unset;
    padding-right: unset;
  }
  .carousel {
    display: none; /* Hide carousel on small screens */
  }
}

/* Projects Cards */
.project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.projects-card {
  border: 1px solid #24292e;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px;
  background-color: #24292e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  color: #ffffff;
}

.projects-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.folder-icon {
  display: flex;
  align-items: center;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-desc {
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.card-tech {
  font-size: 0.9rem;
  font-weight: bold;
}

.card-tech a {
  text-decoration: none;
}

.card-tech a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 788px) {
  .about-image img {
    max-width: 200px;
  }

  .about-image:hover {
    transform: translateY(-7px);
  }

  .hero .hero_text {
    text-align: center;
  }

  .hero_h1 {
    font-size: 36px;
  }

  .hero_name {
    font-size: 36px;
  }

  .hero_subheader {
    font-size: 28px;
    margin: 10px 0;
  }

  .btn-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  .btn-1,
  .btn-2 {
    font-size: 16px;
    padding: 10px 20px;
  }

  .socials_container {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  #about {
    height: unset;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30%;
  }

  #about .section-header {
    padding-left: 0;
    padding-right: 0;
  }

  .about-description {
    font-size: 18px;
  }

  .about-description a,
  .about-description b {
    font-size: 17px;
  }

  .about-image img {
    display: none;
  }

  .about-content {
    display: unset;
  }

  .projects-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .projects-card {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-title {
    font-size: 20px;
    margin: 10px 0;
  }

  .card-desc {
    font-size: 16px;
  }

  .card-tech {
    font-size: 14px;
    color: #555;
  }
}

@media only screen and (max-width: 600px) {
  .hero {
    min-height: unset;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .hero_pic_container {
    padding-bottom: 2rem;
  }

  .btn-1 {
    background: #fff;
    color: #000;
  }

  .btn-1:hover,
  .btn-2:hover {
    cursor: pointer;
  }

  .btn-1:hover {
    background: #36c2ce;
    color: white;
  }

  .btn-2 {
    background: #000;
    color: white;
  }

  .btn-2:hover {
    background: #36c2ce;
    color: #000;
  }

  .about-image img {
    display: none;
  }

  .projects-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .projects-card {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-title {
    font-size: 20px;
    margin: 10px 0;
  }

  .card-desc {
    font-size: 16px;
  }

  .card-tech {
    font-size: 14px;
    color: #555;
  }
}

#experience {
  min-height: 50vh;
  margin: auto;
  max-width: 1000px;
  padding-top: 100px;
}

.MuiTabs-indicator {
  background-color: #36c2ce !important; /* Your custom color here */
}

.joblist-job-title {
  font-size: 28px;
  font-weight: bold;
  color: #eeeeee;
}

.joblist-job-company {
  font-size: 28px;
  color: #36c2ce;
  font-weight: bold;
}

.joblist-duration {
  font-size: 18px;
  color: #fafbfc;
  padding-top: 1.5rem;
}

#vertical-tabpanel {
  margin-top: -28px;
  padding-left: 25px;
}

.MuiTab-wrapper {
  font-size: 16px;
  text-align: left;
  align-items: flex-start !important;
}

.MuiButtonBase-root {
  outline: none !important;
  color: #fafbfc !important;
}

.PrivateTabIndicator-colorSecondary-5 {
  color: #36c2ce !important;
}
.jss5 {
  color: #36c2ce !important;
}
.makeStyles-tabs-2 {
  border-right: 1px solid #36c2ce !important;
}
#experience .MuiTab-root {
  padding: 6px 20px;
}

.css-1aquho2-MuiTabs-indicator {
  color: #36c2ce !important;
}

.MuiButtonBase-root:focus {
  color: #36c2ce !important;
}

/* Your CSS file */
.css-10d9dml-MuiTabs-indicator {
  background-color: #36c2ce !important; /* Change the color */
}

ul {
  list-style: none;
  margin-left: -40px;
}

.css-19kzrtu {
  /* Adjust these values as needed */
  margin-top: -25px; /* Moves the box up by 20px */
  padding-top: 10px; /* Optional: Adds padding at the top if needed */
}

.job-description li::before {
  content: "▹    ";
  color: #36c2ce;
  position: absolute;
  left: 0;
}

.job-description li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 16px;
  font-size: 18px;
  color: #fafbfc;
}

.job-description {
  padding-top: 24px;
  max-width: 650px;
  font-size: 20px;
}

.job-description a,
.job-description b {
  font-size: 19px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  #experience {
    padding-left: unset;
    padding-right: unset;
  }

  .MuiButtonBase-root {
    padding-bottom: 1rem;
  }

  .MuiTabs-indicator {
    background-color: #36c2ce !important; /* Your custom color here */
  }

  .css-ttw4rn {
    background-color: #36c2ce !important;
  }

  .css-1aquho2-MuiTabs-indicator {
    background-color: #36c2ce !important;
  }

  .jss1 {
    height: unset !important;
    flex-direction: column !important;
  }

  .makeStyles-root-1 {
    height: unset !important;
    width: unset;
    flex-direction: column;
  }

  .joblist-job-title {
    font-size: 22px;
  }

  .joblist-job-company {
    font-size: 22px;
  }

  .MuiTabs-root {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .MuiTabs-flexContainer {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .MuiTypography-root {
    padding-top: 2rem;
  }

  .MuiTab-root {
    flex: 1 1 auto;
    min-width: 100px;
    max-width: 100px;
    text-align: center;
  }

  .css-10d9dml-MuiTabs-indicator {
    background-color: #36c2ce !important; /* Change the color */
  }
}

#contact {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
}

#contact .section-header {
  padding-top: 8rem;
  padding-bottom: 4rem;
}

.contact-container {
  align-items: center; /* Center items horizontally */
}

.contact-form-header {
  padding-bottom: 2rem;
  font-size: 18px;
  color: #fafbfc;
}

.contact-form-header-email {
  color: #36c2ce;
}

.contact-form-container {
  width: 100%;
  max-width: 600px; /* Adjust width as needed */
  background: #24292e; /* Background color for form */
  padding: 2rem; /* Padding for form */
  align-items: center; /* Center items horizontally */

  border-radius: 10px; /* Border radius for form */
  color: #fafbfc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Box shadow for form */
}

.contact-form-container form {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.contact-form-container input,
.contact-form-container textarea {
  width: 100%;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  margin-top: 0.5rem;
}

.contact-form-container input:focus,
.contact-form-container textarea:focus {
  border: 2px solid #36c2ce;
}

.contact-form-container input[type="submit"] {
  margin-top: 2rem;
  cursor: pointer;
  background: #36c2ce;
  color: white;
  border: none;
}

@media only screen and (max-width: 400px) {
  .MuiTabs-indicator {
    background-color: #36c2ce;
  }
}
/* Responsive Styles */

/* For screens with max-width 900px */
@media only screen and (max-width: 900px) {
  #contact {
    padding: 1rem; /* Reduce padding to fit smaller screens */
  }

  #contact .section-header {
    padding-top: 5rem; /* Adjust padding for header */
  }

  .contact-container {
    align-items: center;
  }
  .contact-form-header h2 {
    font-size: 20px; /* Adjust font size for smaller screens */
    align-items: center;
  }

  .contact-form-container {
    padding: 1.5rem; /* Reduce padding for smaller screens */
  }
}

/* For screens with max-width 400px */
@media only screen and (max-width: 450px) {
  #contact .section-header {
    padding-top: 35rem; /* Adjust padding for header */
    width: 100%; /* Ensure it takes full width */
    padding-left: 0;
  }

  .contact-form-header {
    font-size: 14px; /* Further adjust font size for very small screens */
  }

  .contact-form-container {
    padding: 1rem; /* Further reduce padding for very small screens */
    max-width: 100%; /* Ensure container fits screen */
  }

  .contact-form-container input,
  .contact-form-container textarea {
    padding: 5px; /* Reduce padding inside form elements */
  }

  .contact-form-container input[type="submit"] {
    margin-top: 1.5rem; /* Adjust margin for submit button */
  }
}

#footer {
  text-align: center;
  padding: 1rem;
  color: #fafbfc;
}
